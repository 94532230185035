import React from 'react';
import { Else, If, Then } from 'react-if';
import d from 'dayjs';
import { Comment } from '../Comment';
import { Button } from '../Button';
import { Anchor } from '../Text';
import { useLimitList } from '../../hooks/useLimitList';
import { YaAndGaCruisePage, YaAndGaShipPage } from '../../lib/YaAndGaHelper';
import styles from './reviews.module.sass';

export const Reviews = ({
  items = [],
  link,
  limit = 3,
  noButton,
  clipp,
  setClipp,
  isMobile,
  isModal,
  onClickAction,
  onActionClick,
}) => {
  const { show, isCanShowMore, onShowMore } = useLimitList({
    count: items.length,
    limit,
  });
  return (
    <>
      {items.length > 0 &&
        (limit ? items.slice(0, show) : items).map((item, index) => (
          <Comment
            key={index}
            userPick={
              item.user_img &&
              `${process.env.NEXT_PUBLIC_BASE_URL}/${item.user_img}`
            }
            name={item.username}
            date={d(item.r_date).format('DD MMMM YYYY')}
            link={item.url}
            ship={item.t_title}
            stars={item.raiting_company}
            starShip={item.raiting_ship}
            reviewPhoto={item.fotos}
            clipp={clipp}
            setClipp={setClipp}
            isMobile={isMobile}
            isModal={isModal}
          >
            {item.review}
          </Comment>
        ))}
      {!noButton && (
        <If condition={!link}>
          <Then>
            {isCanShowMore && (
              <Button
                size="lg"
                onClick={() => {
                  onShowMore();
                  // YaAndGaCruisePage(onClickAction);
                  // YaAndGaShipPage(onActionClick);
                }}
                fullWidth
              >
                Загрузить ещё отзывы
              </Button>
            )}
          </Then>
          <Else>
            <div className={styles.infoWrapper}>
              <div className={styles.info}>
                <Anchor
                  className={styles.button}
                  href={link}
                  target="_blank"
                  variant="linkAsbutton2"
                >
                  Загрузить ещё отзывы
                </Anchor>
              </div>
            </div>
          </Else>
        </If>
      )}
    </>
  );
};
